import { Outlet } from 'react-router-dom';
import { Container, ContentContainer } from './styles';
import SideMenu from './components/SideMenu/SideMenu';
import Footer from '../../components/Footer/Footer';

export default function MainContainer() {
  return (
    <Container>
      <SideMenu />
      <ContentContainer>
        <Outlet />
        <Footer />
      </ContentContainer>
    </Container>
  );
}
