import { Alert, Button, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch } from '../../../app/store';
import IgbTextField from '../../../components/CustomTextField/TextField';
import {
  authenticate,
  selectIsLoading,
  selectRedirectToSplitRegistration,
  signOut,
} from '../../../features/auth/authSlice';
import { PasswordContainer, SignUpContainer } from './styles';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from '../styles';
import { useEffect } from 'react';
import { display } from '@mui/system';

export default function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const isRedirectToSplitReg = useSelector(selectRedirectToSplitRegistration);

  useEffect(() => {
    dispatch(signOut());
  }, []);

  const handleOnSubmit = async ({ username, password }: any) => {
    const loginInfo = await dispatch(authenticate({ username, password }));
    const isFulfilled = loginInfo.meta.requestStatus === 'fulfilled';
    const isEmailVerified = (loginInfo.payload as any)?.isEmailVerified;
    if (isFulfilled && !isEmailVerified) navigate('/auth/email-verification');
    else if (isFulfilled && isRedirectToSplitReg)
      navigate('/admin/split-registration');
    else if (isFulfilled && isEmailVerified) navigate('/home');
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('E-mail is a required field'),
    password: Yup.string().required('Password is a required field'),
  });

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty } = formik;
        return (
          <Form>
            <Typography component="h1">Login to iGB</Typography>
            <Field
              component={IgbTextField}
              autoComplete="new-password"
              name="username"
              label="E-mail"
            />
            <PasswordContainer>
              <Field
                component={IgbTextField}
                autoComplete="new-password"
                name="password"
                type="password"
                label="Password"
              />
              <Button variant="text" disabled={isLoading}>
                <Link to="/auth/password-recovery">Forgot password?</Link>
              </Button>
            </PasswordContainer>
            {isRedirectToSplitReg && (
              <Alert severity="warning">
                An Account already exists for that email address. Login to add a
                new Registration Code to your existing Account.
              </Alert>
            )}
            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!dirty || !isValid}
              variant="contained"
            >
              Login
            </LoadingButton>
            <Typography component="p" textAlign="center">
              <b>Received a registration code?</b>
            </Typography>
            <div style={{ display: 'flex' }}>
              <SignUpContainer style={{ borderRight: '1px gray solid' }}>
                <Typography component="p">Existing user</Typography>
                <Typography component="p" variant="body2" textAlign="center">
                  <i>
                    Login and proceed to
                    <br />
                    Admin -{'>'} Link Registration.
                  </i>
                </Typography>
              </SignUpContainer>
              <SignUpContainer>
                <Typography component="p">New user</Typography>
                <Button variant="text" disabled={isLoading}>
                  <Link to="/auth/sign-up">Register now!</Link>
                </Button>
              </SignUpContainer>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
