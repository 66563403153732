import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommonState, CommonStatus } from '../../models/common-state';
import { defaultError } from '../../app/common-redux-use';
import axios from 'axios';
import { RootState } from '../../app/store';
import { FileModel } from '../../models/FileModel';

enum FileStatusEnum {
  empty = 'empty',
}

type FileStatus = CommonStatus | FileStatusEnum;
const FileStatus = { ...FileStatusEnum, ...CommonStatus };

export interface FileState extends CommonState {
  files: FileModel[];
  page: number | null;
  count: number;
  errorMessage: string | null;
  status: FileStatus[keyof FileStatus];
}

const initialState: FileState = {
  files: [],
  page: null,
  count: 0,
  errorMessage: null,
  status: FileStatus.empty,
};

const filesURL = `${process.env.REACT_APP_API_URL!}statement/files`;

export const getFiles = createAsyncThunk(
  'files/getFiles',
  async ({ params, page }: { params: any; page: number }) => {
    const { count, results } = (
      await axios.get<{ count: number; results: FileModel[] }>(filesURL, {
        params: {
          ...params,
          limit: 100,
          offset: 100 * page,
        },
      })
    ).data;

    return {
      files: results,
      count,
      page,
    };
  }
);

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFiles.pending, (state) => {
        state.status = FileStatus.loading;
        state.files = [];
      })
      .addCase(getFiles.rejected, defaultError)
      .addCase(getFiles.fulfilled, (state, action) => {
        const { count, page, files } = action.payload;
        state.count = count;
        state.files = files;
        state.page = page;
        state.status = FileStatus.success;
      });
  },
});

export const selectFiles = (state: RootState) => state.files.files;

export const selectIsLoading = (state: RootState) =>
  state.files.status === FileStatus.loading;

export const selectCount = (state: RootState) => state.files.count;

export default filesSlice.reducer;
