import { Chip, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppDispatch } from '../../../../app/store';
import {
  getFiles,
  selectCount,
  selectFiles,
  selectIsLoading,
} from '../../../../features/files/filesSlice';
import UploadModal from './components/UploadModal';

const TableContainer = styled.div`
  min-height: 400px;
  height: 45vh;
`;

export default function ManageFiles() {
  const dispatch = useDispatch<AppDispatch>();

  const files = useSelector(selectFiles);
  const isLoading = useSelector(selectIsLoading);
  const count = useSelector(selectCount);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSort, setCurrentSort] = useState<{ ordering: string } | null>(
    null
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'File ID', minWidth: 150, flex: 1 },
    {
      field: 'customer__name',
      headerName: 'Operator',
      flex: 1,
      minWidth: 250,
      valueGetter: (params) => params.row.customer.name,
    },
    { field: 'filename', headerName: 'File Name', minWidth: 250, flex: 1 },
    {
      field: 'filedate',
      headerName: 'File Date',
      minWidth: 120,
      valueFormatter: (date) => format(new Date(date.value), 'MM-dd-yyyy'),
    },
    {
      field: 'status',
      headerName: 'File Status',
      width: 120,
      renderCell: (params: GridRenderCellParams<any>) => {
        const value = params.value;
        return (
          <Chip
            label={value}
            color={
              value === 'Success'
                ? 'success'
                : value === 'Fail'
                ? 'error'
                : 'default'
            }
          />
        );
      },
    },
  ];

  useEffect(() => {
    const sort = currentSort || {};

    dispatch(
      getFiles({
        params: sort,
        page: currentPage,
      })
    );
  }, [currentPage, currentSort]);

  return (
    <>
      <UploadModal isDisabled={true} />
      <TableContainer>
        <DataGrid
          rows={files}
          columns={columns}
          onPageChange={setCurrentPage}
          onSortModelChange={(sortModel) => {
            if (sortModel.length > 0) {
              setCurrentSort({
                ordering: `${sortModel[0].sort === 'desc' ? '-' : ''}${
                  sortModel[0].field
                }`,
              });
            }
          }}
          loading={isLoading}
          pageSize={100}
          page={currentPage}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick={true}
          rowCount={count}
          disableColumnMenu={true}
          paginationMode="server"
          sortingMode="server"
        />
      </TableContainer>
    </>
  );
}
