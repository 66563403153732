import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Field } from 'formik';
import useToggle from '../../helpers/useToggle';

export default function CustomCheckbox({
  field,
  form: { setFieldValue },
  ...props
}: any) {
  const [isChecked, toggle] = useToggle(!!field.value);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Field {...props}>
            {({ field }: any) => (
              <Checkbox
                {...field}
                checked={isChecked}
                onChange={() => {
                  setFieldValue(field.name, !isChecked);
                  toggle();
                }}
              />
            )}
          </Field>
        }
        {...field}
        {...props}
      />
    </FormGroup>
  );
}
