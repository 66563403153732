import { Drawer } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
`;

export const ContentContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primaryContrast['600']};
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyContentContainer = styled.div`
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SideMenu = styled(Drawer)`
  & .MuiDrawer-paper {
    background-color: #143166;
  }

  & .MuiListItemIcon-root,
  .MuiDrawer-paper {
    color: white;
  }
`;
