import PageHeader from '../components/PageHeader/PageHeader';
import { BodyContentContainer } from '../styles';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../features/auth/authSlice';

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComingSoonText = styled.span`
  margin-top: 20vh;
  font-size: 2rem;
`;

export default function Home({ title }: { title: string }) {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <>
      <PageHeader title={title} />
      <BodyContentContainer>
        <ComingSoonContainer>
          <ComingSoonText>Welcome, {currentUser?.firstname}!</ComingSoonText>
        </ComingSoonContainer>
      </BodyContentContainer>
    </>
  );
}
