import PageHeader from '../components/PageHeader/PageHeader';
import { BodyContentContainer } from '../styles';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@mui/material';
import styled from 'styled-components';

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ComingSoonText = styled.span`
  margin-top: 20vh;
  font-size: 2rem;
`;

export default function ComingSoon({ title }: { title: string }) {
  return (
    <>
      <PageHeader title={title} />
      <BodyContentContainer>
        <ComingSoonContainer>
          <ComingSoonText>Coming soon!</ComingSoonText>
        </ComingSoonContainer>
      </BodyContentContainer>
    </>
  );
}
