import _ from 'lodash';

export const sanitizeValues = (values: any) => {
  let _filters = _.cloneDeep(values);
  if (typeof _filters.sales_date__gte !== 'string') {
    _filters.sales_date__gte = _filters.sales_date__gte
      .toISOString()
      .slice(0, 10);
  }
  if (typeof _filters.sales_date__lte !== 'string') {
    _filters.sales_date__lte = _filters.sales_date__lte
      .toISOString()
      .slice(0, 10);
  }
  if (typeof _filters.statement_date__gte !== 'string') {
    _filters.statement_date__gte = _filters.statement_date__gte
      .toISOString()
      .slice(0, 10);
  }
  if (typeof _filters.statement_date__lte !== 'string') {
    _filters.statement_date__lte = _filters.statement_date__lte
      .toISOString()
      .slice(0, 10);
  }

  _filters = {
    ..._filters,
    [`property_name${_filters.propertyNameMode}`]:
      typeof _filters.property_name === 'string' ? _filters.property_name : '',
  };
  delete _filters.propertyNameMode;
  delete _filters.property_name;

  const filters = Object.entries(_filters).reduce(
    (filterProps: any, prop: [string, any]) => {
      if (_.isBoolean(prop[1]) || prop[1].length > 0) {
        filterProps[prop[0]] = prop[1];
      }
      return (filterProps = { ...filterProps });
    },
    {}
  );

  if (filters.is_current === 'indeterminate') {
    delete filters.is_current;
  }

  return filters;
};
