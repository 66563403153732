import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

const TooltipBody = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export default function InfoBubble({ children }: any) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.white',
            color: 'common.black',
          },
        },
      }}
      title={
        <>
          <TooltipBody>{children}</TooltipBody>
        </>
      }
    >
      <InfoIcon color="info" fontSize="small" />
    </Tooltip>
  );
}
