import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommonState, CommonStatus } from '../../models/common-state';
import { defaultError, defaultLoading } from '../../app/common-redux-use';
import axios from 'axios';
import { RootState } from '../../app/store';
import { SenderModel } from '../../models/SendersModel';

enum SenderStatusEnum {
  empty = 'empty',
}

type SenderStatus = SenderStatusEnum | CommonStatus;
const SenderStatus = { ...SenderStatusEnum, ...CommonStatus };

export interface StatementState extends CommonState {
  senders: SenderModel[];
  page: number | null;
  count: number;
  errorMessage: string | null;
  status: SenderStatus[keyof SenderStatus];
}

const initialState: StatementState = {
  senders: [],
  page: null,
  count: 0,
  errorMessage: null,
  status: SenderStatus.empty,
};

const sendersURL = `${process.env.REACT_APP_API_URL!}statement/sender`;

export const getSenders = createAsyncThunk(
  'statements/getSenders',
  async () => {
    const senders = (await axios.get<any>(sendersURL)).data;

    return { senders };
  }
);

export const sendersSlice = createSlice({
  name: 'senders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSenders.pending, defaultLoading)
      .addCase(getSenders.rejected, defaultError)
      .addCase(getSenders.fulfilled, (state, action) => {
        state.senders = action.payload.senders;
        state.status = SenderStatus.success;
      });
  },
});

export const selectIsLoading = (state: RootState) =>
  state.sender.status === SenderStatus.loading;

export const selectSenders = (state: RootState) => state.sender.senders;
export const selectSendersIDs = (state: RootState) =>
  state.sender.senders.map((sender) => sender.id);
export const selectSendersAsKeyValue = (state: RootState) =>
  state.sender.senders.reduce(
    (acc, sender) => ({ ...acc, [sender.id]: sender.name }),
    {} as { [key: string]: string }
  );

export default sendersSlice.reducer;
