import { FormControl, InputLabel, Select, TextField } from '@mui/material';
import { get } from 'lodash';
import { useState } from 'react';

export const IgbTextFieldWithSubSelecton = ({
  field,
  form: { touched, errors },
  select: { value, options, onChange, onBlur, name },
  ...props
}: any) => {
  const error = get(touched, field.name) && !!get(errors, field.name);
  const helperText = get(touched, field.name) && get(errors, field.name);

  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const onInputFocus = () => setIsInputFocused(true);
  const onInputBlur = () => setIsInputFocused(false);

  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const onSelectFocus = () => setIsSelectFocused(true);
  const onSelectBlur = () => setIsSelectFocused(false);

  return (
    <FormControl fullWidth>
      <InputLabel>{field.label}</InputLabel>
      <div style={{ display: 'flex' }}>
        <TextField
          {...field}
          {...props}
          variant="outlined"
          error={error}
          helperText={helperText}
          onFocus={onInputFocus}
          onBlur={(ev) => {
            field.onBlur(ev);
            onInputBlur();
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          sx={{
            flex: 1,
            '& div > fieldset': {
              borderRight: 'none',
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              borderColor:
                isInputFocused || isSelectFocused
                  ? '#1976d2'
                  : `rgba(0, 0, 0, ${isHovered ? 0.87 : 0.23})`,
              borderWidth: isInputFocused || isSelectFocused ? '2px' : '1px',
            },
          }}
        />
        <Select
          name={name}
          value={value}
          onChange={(ev) => {
            onChange(ev);
            props.onChange(ev);
          }}
          variant="outlined"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onFocus={onSelectFocus}
          onBlur={(ev) => {
            onBlur(ev);
            onSelectBlur();
          }}
          sx={{
            '& fieldset': {
              borderLeft: 'none',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              borderColor:
                isInputFocused || isSelectFocused
                  ? '#1976d2'
                  : `rgba(0, 0, 0, ${isHovered ? 0.87 : 0.23})`,
              borderWidth: isInputFocused || isSelectFocused ? '2px' : '1px',
            },
          }}
        >
          {options}
        </Select>
      </div>
    </FormControl>
  );
};

export default IgbTextFieldWithSubSelecton;
