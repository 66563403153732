import { createSlice } from '@reduxjs/toolkit';
import { defaultError } from '../../app/common-redux-use';
import { RootState } from '../../app/store';
import { CommonState, CommonStatus } from '../../models/common-state';

export interface SideMenuState extends CommonState {
  isCollapsed: boolean;
  status: CommonStatus;
  errorMessage: string | null;
}

const initialState: SideMenuState = {
  isCollapsed: false,
  status: CommonStatus.success,
  errorMessage: null,
};

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    setIsCollapsed: (state, action) => {
      return { ...state, isCollapsed: action.payload.isCollapsed };
    },
  },
});

export const selectIsCollapsed = (state: RootState) =>
  state.sideMenu.isCollapsed;

export const { setIsCollapsed } = sideMenuSlice.actions;

export default sideMenuSlice.reducer;
