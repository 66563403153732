import { Button, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch } from '../../../../app/store';
import IgbTextField from '../../../../components/CustomTextField/TextField';
import {
  cancelForgotPasswordProccess,
  generateCodeForgotPassword,
  selectIsLoading,
} from '../../../../features/auth/authSlice';
import { Form } from '../../styles';
import LoadingButton from '@mui/lab/LoadingButton';

export default function PasswordRecoveryGenerateCode({
  setEmail,
  setStep,
}: any) {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(selectIsLoading);

  const handleOnSubmit = async ({ email }: { email: string }) => {
    const generateCodeInfo = await dispatch(generateCodeForgotPassword(email));
    if (generateCodeInfo.meta.requestStatus === 'fulfilled') {
      setEmail(email);
      setStep(1);
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is a required field'),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty } = formik;
        return (
          <Form>
            <Typography component="h1">Recover your password</Typography>
            <Typography component="p">
              You'll receive a confirmation code on your e-mail.
            </Typography>
            <Field component={IgbTextField} name="email" label="Your email" />
            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!dirty || !isValid}
              variant="contained"
            >
              Send code to my email
            </LoadingButton>
            <Button
              variant="text"
              onClick={() => dispatch(cancelForgotPasswordProccess)}
            >
              <Link to="/auth/login">Back to login</Link>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
