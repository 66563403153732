export enum CommonStatus {
  loading = 'loading',
  failed = 'failed',
  success = 'success',
}

export type CommonState = {
  status: CommonStatus[keyof CommonStatus];
  errorMessage: string | null;
};
