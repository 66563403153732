import { CommonStatus } from '../models/common-state';

export const defaultError = (state: any, action: any) => {
  return {
    ...state,
    status: CommonStatus.failed,
    errorMessage: (action as any)?.error.message,
  };
};

export const defaultLoading = (state: any) => {
  return { ...state, status: CommonStatus.loading };
};
