import { Link } from '@mui/material';
import styled from 'styled-components';

export const FooterContainer = styled.div`
  padding: 1rem;
  background-color: #5675ac;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.5rem;
  color: white;

  & a {
    color: white;
    text-decoration-color: white;
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <span>
        For questions, suggestions or concerns please contact
        iGBSupport@CapitalizeConsulting.com.
      </span>
      <span>
        <Link
          rel="noreferrer noopener"
          target="_blank"
          href={process.env.REACT_APP_TERMS_OF_USE_URL}
        >
          Terms of Use
        </Link>{' '}
        |{' '}
        <Link
          rel="noreferrer noopener"
          target="_blank"
          href={process.env.REACT_APP_PRIVACY_POLICY_URL}
        >
          Privacy Policy
        </Link>
      </span>
    </FooterContainer>
  );
}
