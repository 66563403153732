import { useState } from 'react';
import PasswordRecoveryConfirm from './components/PasswordRecoveryConfirm';
import PasswordRecoveryGenerateCode from './components/PasswordRecoveryGenerateCode';

export default function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(0);

  return (
    <>
      {step === 0 ? (
        <PasswordRecoveryGenerateCode setEmail={setEmail} setStep={setStep} />
      ) : (
        <PasswordRecoveryConfirm email={email} />
      )}
    </>
  );
}
