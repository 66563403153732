import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  background-color: #143166;
  padding: 5vh 5rem;
  box-sizing: border-box;
`;

export const Img = styled.img`
  padding-bottom: 2rem;
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 40vw;
`;
