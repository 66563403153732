import { Outlet } from 'react-router';
import PageHeader from '../components/PageHeader/PageHeader';
import { BodyContentContainer } from '../styles';

export default function Admin() {
  return (
    <>
      <PageHeader title="Admin" />
      <BodyContentContainer>
        <Outlet />
      </BodyContentContainer>
    </>
  );
}
