import { Breadcrumbs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import styled from 'styled-components';

const BreadCrumbLink = styled(Link)`
  &:not(:hover) {
    text-decoration: none;
  }

  color: ${({ theme }) => theme.colors.primary['600']};
`;

const CustomBreadCrumb = styled(Breadcrumbs).attrs(
  (props: { show: number }) => props
)`
  ${({ show }) => (show ? undefined : `display: none;`)}
`;

export default function IgbBreadcrumbs() {
  const location = useLocation();
  const [path, setPath] = useState<string[]>([]);
  const homePath = 'home';
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const currPath = location.pathname.split('/');
    setPath(currPath);
    setShow(currPath.length > 1 && !currPath[1].includes(homePath));
  }, [location]);

  const getSanitizedPathPart = (pathPart: string) => {
    const splittedPart = pathPart.split('-').join(' ');
    return splittedPart.charAt(0).toUpperCase() + splittedPart.slice(1);
  };

  const getAbsolutePath = (path: string[], index: number) => {
    return path.slice(0, index + 1).join('/');
  };

  return (
    <CustomBreadCrumb show={+show}>
      {path.map((pathPart: string, index: number) => {
        const sanitizedPathPart = getSanitizedPathPart(pathPart);
        if (index === path.length - 1) {
          return (
            <Typography key={pathPart + index}>{sanitizedPathPart}</Typography>
          );
        }
        return (
          <BreadCrumbLink to={getAbsolutePath(path, index)} key={pathPart}>
            {sanitizedPathPart.length > 0 ? sanitizedPathPart : <HomeIcon />}
          </BreadCrumbLink>
        );
      })}
    </CustomBreadCrumb>
  );
}
