import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useToggle from '../../../../../helpers/useToggle';
import styled from 'styled-components';
import { SideMenuItem } from '../styles';
import { ReactElement, ReactNode, useEffect } from 'react';

const ExpandContainer = styled.div`
  margin-left: auto;
`;

const SubMenuContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary['600']};

  & ${SideMenuItem}:hover {
    background-color: ${({ theme }) => theme.colors.primary['700']};
  }
`;

export default function SideMenuExpandItem({
  isActive,
  isExpanded,
  children,
  icon,
  title,
  route,
  onClick,
}: {
  isActive: boolean;
  isExpanded: boolean;
  children: ReactNode;
  icon: ReactElement<any, any>;
  title: string;
  route?: string;
  onClick: () => void;
}) {
  const [expanded, toggle] = useToggle(isExpanded);

  useEffect(() => {
    !isActive && expanded && toggle();
  }, [isActive]);

  return (
    <>
      <SideMenuItem
        active={isActive}
        key={title + route}
        onClick={() => {
          onClick();
          toggle();
        }}
      >
        {icon}
        <span>{title}</span>
        <ExpandContainer>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ExpandContainer>
      </SideMenuItem>
      {expanded && isActive && <SubMenuContainer>{children}</SubMenuContainer>}
    </>
  );
}
