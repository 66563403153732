import {
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const OuterSwitch = styled.div.attrs(
  (props: { indeterminate: boolean; checked: boolean }) => props
)`
  position: relative;
  height: 100%;
  margin-right: 10px;

  & > button,
  & > span {
    background-color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    position: absolute;
  }

  & > button {
    background-color: transparent;
    z-index: 2;
    opacity: 0.2;
    width: 40px;
    height: 40px;
    ${({ indeterminate, checked }) => {
      return indeterminate
        ? 'left: calc(50% - 20px)'
        : checked
        ? 'left: calc(100% - 30px);'
        : 'left: -10px;';
    }};

    &:hover {
      ${({ indeterminate, checked }) => {
        return indeterminate
          ? 'background-color: rgba(0, 0, 0, 0.04);'
          : checked
          ? 'background-color: rgba(0, 100, 0, 0.16); color: green;'
          : 'background-color: rgba(100, 0, 0, 0.16); color: #fccad3;';
      }};
    }
  }

  & > span {
    z-index: 1;
    width: 20px;
    height: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    ${({ indeterminate, checked }) => {
      return indeterminate
        ? 'left: calc(50% - 10px)'
        : checked
        ? 'left: calc(100% - 20px); background-color: green;'
        : 'left: 0; background-color: #fccad3;';
    }};
  }

  & > div {
    position: absolute;
    width: 34px;
    height: 14px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 7px;
    background-color: black;
    opacity: 0.38;
    position: relative;
    z-index: 0;
    top: 50%;
    transform: translateY(-50%);

    ${({ indeterminate, checked }) => {
      return indeterminate
        ? ';'
        : checked
        ? 'background-color: green;'
        : 'background-color: #fccad3;';
    }}
  }
`;

export default function CustomIndeterminateSwitch({
  field,
  form: { setFieldValue },
  ...props
}: any) {
  const [checkboxState, setCheckboxState] = useState<boolean | 'indeterminate'>(
    field.value
  );

  const [lastBool, setLastBool] = useState<boolean>(!!field.value);

  const [isChecked, setIsChecked] = useState<boolean>(!!field.value);

  const getNextState = (currState: boolean | 'indeterminate') => {
    let nextState;
    if (typeof currState === 'boolean') {
      nextState = 'indeterminate';
      setLastBool(currState);
    }
    if (currState === 'indeterminate') nextState = !lastBool;
    return nextState;
  };

  const handleClick = (ev: any) => {
    props.onChange(ev);
    // @ts-ignore
    setCheckboxState((currState) => {
      const nextState = getNextState(currState);
      setIsChecked(!!nextState);
      return nextState;
    });
  };

  useEffect(() => {
    setFieldValue(field.name, checkboxState);
  }, [checkboxState]);

  useEffect(() => {
    setFieldValue(field.name, field.value);
    setCheckboxState(field.value);
  }, [field.value]);

  return (
    <FormGroup style={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Field {...props}>
            {({ field }: any) => (
              <OuterSwitch
                indeterminate={checkboxState === 'indeterminate'}
                checked={isChecked}
              >
                <span></span>
                <ButtonBase
                  centerRipple={true}
                  id={field.name}
                  onClick={handleClick}
                ></ButtonBase>
                <div></div>
              </OuterSwitch>
            )}
          </Field>
        }
        {...field}
        {...props}
      />
    </FormGroup>
  );
}
