import PageHeader from '../components/PageHeader/PageHeader';
import { BodyContentContainer } from '../styles';
import StatementFilters from './components/Filters';
import { GridCellParams, GridSortModel } from '@mui/x-data-grid';
import {
  DataGridPro,
  useGridApiRef,
  GridEvents,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getStatementBulkFiles,
  getStatementFile,
  getStatements,
  selectCount,
  selectIsLoading,
  selectShowStatements,
  selectStatements,
  setShowStatements,
} from '../../../features/statements/statementSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { selectIsAuthenticated } from '../../../features/auth/authSlice';
import _ from 'lodash';
import {
  getSenders,
  selectSenders,
} from '../../../features/senders/senderSlice';
import CustomPagination from './components/CustomPagination';
import { sanitizeValues } from './SanitizeFormValues';
import { COLUMNS } from './Columns';
import { Stack, Typography } from '@mui/material';
import Search from '../../../search.svg';

const StatementTableContainer = styled.div`
  min-height: 400px;
  height: 45vh;
`;

export const Img = styled.img`
  width: 20%;
  object-fit: contain;
  margin-top: 5rem;
`;

export default function Statement() {
  const isLoading = useSelector(selectIsLoading);
  const showStatements = useSelector(selectShowStatements);
  const dispatch = useDispatch<AppDispatch>();

  const columns = COLUMNS;

  const [idsSelected, setIdsSelected] = useState<string[]>([]);

  const statements = useSelector(selectStatements);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const count = useSelector(selectCount);

  const senders = useSelector(selectSenders);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const initialSort: GridSortModel = [
    { field: 'property__header_name', sort: 'asc' },
    { field: 'statement__sales_date', sort: 'desc' },
    { field: 'statement__statement_date', sort: 'desc' },
  ];
  const [currentSort, setCurrentSort] = useState<GridSortModel>(initialSort);
  const resetSort = () => {
    setCurrentSort(initialSort);
  };
  const [currentFilters, setCurrentFilters] = useState<any>(null);

  const handleOnSubmit = (f: any) => {
    setCurrentPage(0);
    setCurrentFilters(sanitizeValues(f));
  };

  const handleOnDownload = async () => {
    if (idsSelected.length > 1) {
      const ids = new Set(idsSelected);
      const _statements = statements
        .filter((statement) => {
          return ids.has(statement.statement_id);
        })
        .map((statement) => statement.statement_id)
        .join(',');
      await dispatch(
        getStatementBulkFiles({
          statements: _statements,
        })
      );
    } else {
      await dispatch(
        getStatementFile({
          statement: statements.find(
            (statement) => statement.statement_id === idsSelected[0]
          )!,
        })
      );
    }
  };

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (showStatements) {
      const handleColumnHeaderClick = (
        { colDef }: GridCellParams,
        event: MuiEvent<React.MouseEvent<HTMLElement>>
      ) => {
        event.defaultMuiPrevented = true;
        apiRef.current.sortColumn(colDef, undefined, true);
      };

      apiRef.current.subscribeEvent(
        GridEvents.columnHeaderClick,
        handleColumnHeaderClick as any,
        { isFirst: true }
      );
    }
  }, [apiRef, showStatements]);

  useEffect(() => {
    const sort = {
      ordering: currentSort
        .reduce((acc: any[], sort: any) => {
          return (acc = [
            ...acc,
            `${sort.sort === 'desc' ? '-' : ''}${sort.field}`,
          ]);
        }, [])
        .join(','),
    };
    const filters = currentFilters || {};

    dispatch(
      getStatements({
        params: { ...sort, ...filters },
        page: currentPage,
      })
    );
  }, [isAuthenticated, currentPage, currentSort, currentFilters]);

  useEffect(() => {
    dispatch(getSenders());
  }, []);

  return (
    <>
      <PageHeader title="Statement" />
      <BodyContentContainer>
        <StatementFilters
          senders={senders}
          handleOnSubmit={handleOnSubmit}
          handleOnDownload={handleOnDownload}
          handleResetSorting={resetSort}
          idsSelected={idsSelected}
        />
        {showStatements ? (
          <StatementTableContainer>
            <DataGridPro
              disableColumnMenu={true}
              rows={statements}
              getRowId={(row) => row.statement_id}
              columns={columns}
              apiRef={apiRef}
              loading={isLoading}
              onSelectionModelChange={(selectionModel: any) => {
                setIdsSelected(selectionModel);
              }}
              onPageChange={setCurrentPage}
              sortModel={currentSort}
              onSortModelChange={setCurrentSort}
              pageSize={25}
              page={currentPage}
              checkboxSelection
              rowCount={count}
              pagination={true}
              paginationMode="server"
              sortingMode="server"
              components={{
                Footer: CustomPagination,
              }}
            />
          </StatementTableContainer>
        ) : (
          <Stack gap={2} alignItems="center">
            <Img src={Search} />
            <Typography>
              <i>Begin search.</i>
            </Typography>
          </Stack>
        )}
      </BodyContentContainer>
    </>
  );
}
