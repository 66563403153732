import { createTheme } from '@mui/material';

export const Theme = {
  colors: {
    primary: {
      '50': '#e3e6ec',
      '100': '#b9c1d1',
      '200': '#8d99b2',
      '300': '#637393',
      '400': '#445780',
      '500': '#223d6d',
      '600': '#1c3766',
      '700': '#142e5b',
      '800': '#0e264f',
      '900': '#061738',
    },
    primaryContrast: {
      '500': '#e3edfc',
      '600': '#f0f5fc',
      '800': '#f7fafc',
    },
    success: '#00bd33',
    error: '#bd0000',
  },
};

export const MuiTheme = createTheme({
  palette: {
    primary: {
      light: Theme.colors.primary[300],
      main: Theme.colors.primary[600],
      dark: Theme.colors.primary[900],
      contrastText: Theme.colors.primaryContrast[600],
    },
  },
});

export default Theme;
