import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Theme from './Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';
import { IntlProvider } from 'react-intl';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE!);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <SnackbarProvider maxSnack={3}>
    <Provider store={store}>
      <IntlProvider locale={'en'}>
        <BrowserRouter>
          <ThemeProvider theme={Theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
