import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { getLastDayOfMonth } from '../../helpers/getDayOfMonth';

export const IgbDatePicker = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}: any) => {
  const error = get(touched, field.name) && !!get(errors, field.name);
  const helperText = get(touched, field.name) && get(errors, field.name);

  const [value, _setValue] = useState<Date | string>(field.value);
  const [isOpen, setOpen] = useState(false);

  const setValue = (v: Date | string) => {
    setPrevValue(value);
    const isString = typeof v === 'string';
    _setValue(isString ? v : format(v, props.inputFormat));
  };

  const [prevValue, setPrevValue] = useState<string | Date>('');

  const newField = {
    value,
    ...field,
    ...props,
    onChange: (_newValue: Date) => {
      const isMonthChange = typeof props.onMonthChange === 'function';
      const newValue = !isMonthChange
        ? _newValue
        : getLastDayOfMonth(_newValue);

      setValue(newValue);
      if (!!props.onMonthChange) props.onMonthChange();
      if (!!props.onChange) props.onChange();
      setFieldValue(field.name, newValue);
    },
  };

  useEffect(() => {
    if (field.value !== value) setValue(field.value);
  }, [field]);

  const handleOpen = () => {
    setOpen(true);
    setValue('');
    setFieldValue(field.name, '');
  };

  return (
    <DatePicker
      {...newField}
      open={isOpen}
      onClose={() => {
        setOpen(false);
        if (!!props.onCustomClose) props.onCustomClose(prevValue);
      }}
      onOpen={handleOpen}
      closeOnSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, value }}
          fullWidth
          onClick={handleOpen}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default IgbDatePicker;
