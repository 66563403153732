import { differenceInSeconds } from 'date-fns';
import { store } from '../app/store';
import { CommonStatus } from '../models/common-state';

export function showErrorSnackbardOnFailedStatus(
  enqueueSnackbar: (message: string, options: any) => any
) {
  let lastErrorMessage: string;
  let lastErrorDatetime: Date;

  return store.subscribe(() => {
    Object.values(store.getState()).forEach((state) => {
      if (
        state.status! === CommonStatus.failed &&
        (lastErrorMessage !== state.errorMessage! ||
          differenceInSeconds(new Date(), lastErrorDatetime))
      ) {
        lastErrorDatetime = new Date();
        lastErrorMessage = state.errorMessage!;

        enqueueSnackbar(state.errorMessage!, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    });
  });
}
