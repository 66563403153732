import {
  Autocomplete,
  Menu,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from '@mui/material';
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  GridSelectedRowCount,
  selectedGridRowsCountSelector,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectCount } from '../../../../features/statements/statementSlice';

const FooterContainer = styled.div`
  min-height: 52px;
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
`;

const SelectPageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export default function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const itemsSelected = useGridSelector(apiRef, selectedGridRowsCountSelector);
  const totalCount = useSelector(selectCount);

  const options = Array.from(Array(pageCount)).map((p, i) =>
    (i + 1).toString()
  );

  return (
    <FooterContainer>
      <div>
        <span>
          {totalCount > 0
            ? page * pageSize +
              1 +
              '-' +
              (options[options.length - 1] === (page + 1).toString()
                ? totalCount
                : (page + 1) * pageSize) +
              ' of '
            : ''}
          {totalCount} statement{totalCount !== 1 ? 's' : ''}
        </span>
        {itemsSelected > 0 ? (
          <span>
            {' '}
            | <strong>{itemsSelected} selected</strong>
          </span>
        ) : undefined}
      </div>
      <PaginationContainer>
        <SelectPageContainer>
          <span>Page: </span>
          <Autocomplete
            disableClearable={true}
            disablePortal
            options={options}
            value={(page + 1).toString()}
            onChange={(event: any, value: string | null) => {
              if (!!value) apiRef.current.setPage(+value - 1);
            }}
            sx={{ width: '5rem', '&:before': { borderBottom: 'none' } }}
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
        </SelectPageContainer>
        <Pagination
          boundaryCount={0}
          count={pageCount}
          page={page + 1}
          showFirstButton={true}
          showLastButton={true}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
          size="small"
        />
      </PaginationContainer>
    </FooterContainer>
  );
}
