import { TextField } from '@mui/material';
import { get } from 'lodash';

export const IgbTextField = ({
  field,
  form: { touched, errors },
  ...props
}: any) => {
  const error = get(touched, field.name) && !!get(errors, field.name);
  const helperText = get(touched, field.name) && get(errors, field.name);

  return (
    <TextField
      fullWidth
      variant="outlined"
      {...field}
      {...props}
      error={error}
      helperText={helperText}
    />
  );
};

export default IgbTextField;
