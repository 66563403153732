import styled from 'styled-components';

export const SideMenuContainer = styled.aside.attrs(
  (props: { collapsed: boolean; breakpoints: any }) => props
)`
  box-sizing: padding-box;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary['500']};
  transition: all 0.2s ease-in-out;

  ${({ collapsed, breakpoints }) => {
    if (collapsed) {
      return ` 
      ${breakpoints.up('sm')} {
        width: 8vw;
        &:hover {
          width: 9vw;
        }
        ${SideMenuItem} {
          flex-direction: column;
          align-items: center;
          & span, div {
            display: none;
          }
        }
      }
      ${breakpoints.down('sm')} {
        display: none;
      }
      `;
    } else {
      return `
      ${breakpoints.up('sm')} {
        width: 20vw;
        &:hover {
          width: 19vw;
        }
      }
      ${breakpoints.down('sm')} {
        width: 60vw;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
      }
      `;
    }
  }};
`;

export const LogoutContainer = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary['600']};
  align-items: center;
  & button {
    display: flex;
    min-width: fit-content;
    align-items: center;
    ${({ theme }) => {
      return `color: ${theme.colors.primaryContrast['600']};
        border-color: ${theme.colors.primaryContrast['600']};
      `;
    }};
  }
`;

export const SideMenuItem = styled.div.attrs(
  (props: { active: boolean }) => props
)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme, active }) =>
    active
      ? theme.colors.primaryContrast['800']
      : theme.colors.primaryContrast['500']};
  padding: 1rem;
  transition: all 0.1s ease-in-out;

  ${({ active, theme }) =>
    active &&
    `
    border-left: ${theme.colors.primaryContrast['800']} solid 4px;
    background-color: ${theme.colors.primary['800']};
  `}

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.primary['800'] : theme.colors.primary['600']};
    cursor: pointer;
  }
`;

export const SideMenuSubItem = styled(SideMenuItem)`
  ${({ active, theme }) =>
    active &&
    `
    border: none;
    background-color: ${theme.colors.primary['700']};
  `}
`;

export const Img = styled.img`
  width: 50%;
  object-fit: contain;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  background-color: ${(props) => props.theme.colors.primary['600']};
`;

export const EmptyForHover = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  padding: 0.75rem 1rem 1.5rem;

  & > div {
    color: ${(props) => props.theme.colors.primaryContrast['500']};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    margin-top: auto;
  }

  & > div:last-child() {
    width: 100%;
  }
`;
