import { Button, Typography, Link as MuiLink } from '@mui/material';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch } from '../../../app/store';
import IgbTextField from '../../../components/CustomTextField/TextField';
import { selectIsLoading, signUp } from '../../../features/auth/authSlice';
import { SignInContainer } from './styles';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import useQuery from '../../../helpers/useQuery';
import { Form } from '../styles';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';

export default function SignUp() {
  const dispatch = useDispatch<AppDispatch>();
  const queryParams = useQuery();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  const handleOnSubmit = async (userInfo: any) => {
    const signUpInfo = await dispatch(signUp(userInfo));
    if (
      signUpInfo.meta.requestStatus === 'fulfilled' ||
      (signUpInfo as any).error.message.includes(
        'This email is already registered with a different code'
      )
    ) {
      navigate('/auth/login');
    }
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('E-mail is a required field.'),
    repeatUsername: Yup.string()
      .required('Repeat e-mail is a required field')
      .oneOf([Yup.ref('username'), null], 'E-mails must match'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
        'Your password needs to have at least 8 characters, a uppercase letter, a lowercase letter, a number and a special character'
      )
      .required('Password is a required field.'),
    repeatPassword: Yup.string()
      .required('Repeat password is a required field')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    code: Yup.string()
      .length(36, 'Invalid code')
      .required('Code is a required field.'),
    firstname: Yup.string().required('Your first name is a required field.'),
    lastname: Yup.string().required('Your last name is a required field.'),
    phone: Yup.string().required('Your phone number is a required field.'),
    termsAndPrivacyPolicy: Yup.bool().oneOf(
      [true],
      'Accept Terms of Use and Privacy Policy must be checked.'
    ),
  });

  return (
    <Formik
      initialValues={{
        username: '',
        repeatUsername: '',
        password: '',
        repeatPassword: '',
        code: queryParams.get('code') || '',
        firstname: '',
        lastname: '',
        phone: '',
        termsAndPrivacyPolicy: false,
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty, handleBlur } = formik;
        return (
          <Form>
            <Typography component="h1">Sign up to iGB</Typography>
            <Field
              component={IgbTextField}
              name="firstname"
              label="First name"
            />
            <Field component={IgbTextField} name="lastname" label="Last name" />
            <Field component={IgbTextField} name="phone" label="Phone" />
            <Field component={IgbTextField} name="username" label="E-mail" />
            <Field
              component={IgbTextField}
              name="repeatUsername"
              onPaste={(e: any) => e.preventDefault()}
              label="Repeat e-mail"
            />
            <Field
              component={IgbTextField}
              name="password"
              type="password"
              label="Password"
            />
            <Field
              component={IgbTextField}
              name="repeatPassword"
              type="password"
              label="Repeat password"
            />
            <Field component={IgbTextField} name="code" label="Code" />
            <Field
              component={CustomCheckbox}
              name="termsAndPrivacyPolicy"
              onBlur={handleBlur}
              label={
                <span>
                  I agree with the{' '}
                  <MuiLink
                    rel="noreferrer noopener"
                    target="_blank"
                    href={process.env.REACT_APP_TERMS_OF_USE_URL}
                  >
                    Terms of Use
                  </MuiLink>{' '}
                  and{' '}
                  <MuiLink
                    rel="noreferrer noopener"
                    target="_blank"
                    href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  >
                    Privacy Policy
                  </MuiLink>
                  .
                </span>
              }
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!dirty || !isValid}
              variant="contained"
            >
              Sign up
            </LoadingButton>
            <SignInContainer>
              <Typography component="p">Already have an account? </Typography>
              <Button variant="text" disabled={isLoading}>
                <Link to="/auth/login">Sign in!</Link>
              </Button>
            </SignInContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
