import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Modal,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  UploadModalCard,
  UploadButtonContainer,
  CardActionsContainer,
} from '../styles';
import IgbDragNDrop from '../../../../../components/CustomDragNDrop/DragNDrop';

export default function UploadModal({
  isDisabled = false,
}: {
  isDisabled?: boolean;
}) {
  const [filesSelected, setFilesSelected] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFilesSelected((files) => [...files, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop, accept: { 'text/csv': ['.csv'] } });

  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);

  return (
    <UploadButtonContainer>
      <Button
        variant="contained"
        disabled={isDisabled}
        onClick={() => setIsUploadModalOpen(true)}
        disableElevation
      >
        Upload files
      </Button>
      <Modal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UploadModalCard>
          <CardHeader title="Choose files"></CardHeader>
          <CardContent>
            <IgbDragNDrop
              rootProps={getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
              })}
              inputProps={getInputProps()}
            />
            <List>
              {filesSelected.map((file, index) => {
                return (
                  <ListItem key={file.name + index}>
                    <ListItemText
                      primary={file.name}
                      secondary={(file.size / 1000).toFixed(2) + 'kB'}
                    />
                    <Button
                      onClick={() => {
                        setFilesSelected((files) =>
                          files.filter((f, i) => i !== index)
                        );
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
          <CardActionsContainer>
            <CardActions>
              <Button size="small" onClick={() => setIsUploadModalOpen(false)}>
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                disabled={filesSelected.length === 0}
                disableElevation
                onClick={() => setIsUploadModalOpen(true)}
              >
                Upload files
              </Button>
            </CardActions>
          </CardActionsContainer>
        </UploadModalCard>
      </Modal>
    </UploadButtonContainer>
  );
}
