import { FormControl, InputLabel, Select } from '@mui/material';

export default function CustomSelect(props: any) {
  return (
    <FormControl fullWidth sx={{ height: '100%' }}>
      <InputLabel id={props.label + '_select_label'}>{props.label}</InputLabel>
      <Select
        labelId={props.label + '_select_label'}
        id={props.label + '_select'}
        {...props}
      >
        {props.children}
      </Select>
    </FormControl>
  );
}
