import { Field, Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import IgbTextField from '../../../../components/CustomTextField/TextField';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { changePassword } from '../../../../features/auth/authSlice';
import { useSnackbar } from 'notistack';

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export default function ChangePassword() {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnSubmit = async (formValues: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const changePasswordInfo = await dispatch(changePassword(formValues));
    if (changePasswordInfo.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar('Password changed successfuly!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is a required field'),
    newPassword: Yup.string()
      .required('New password is a required field')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
        'Your password needs to have at least 8 characters, a uppercase letter, a lowercase letter, a number and a special character'
      ),
    repeatNewPassword: Yup.string()
      .required('Repeat new password is a required field')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty } = formik;
        return (
          <Form>
            <Field
              component={IgbTextField}
              name="oldPassword"
              type="password"
              label="Old password"
            />
            <Field
              component={IgbTextField}
              name="newPassword"
              type="password"
              label="New password"
            />
            <Field
              component={IgbTextField}
              name="repeatNewPassword"
              type="password"
              label="Repeat new password"
            />
            <ActionsContainer>
              <Button
                type="submit"
                disabled={!dirty || !isValid}
                variant="contained"
              >
                Change password
              </Button>
            </ActionsContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
