import TextField from '@mui/material/TextField';
import {
  DateRangePicker,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function IgbRangeDatePicker({
  startLabel,
  endLabel,
  startName,
  endName,
  form: { setFieldValue },
  ...props
}: any) {
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  useEffect(() => {
    setFieldValue(startName, value[0] || '');
    setFieldValue(endName, value[1] || '');
  }, [value]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={{ start: startLabel, end: endLabel }}
    >
      <DateRangePicker
        disableFuture={true}
        startText={startLabel}
        endText={endLabel}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.onChange();
        }}
        renderInput={(startProps, endProps) => (
          <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
            <TextField {...startProps} fullWidth />
            <TextField {...endProps} fullWidth />
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
}
