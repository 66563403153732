import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Auth } from 'aws-amplify';
import { CommonState, CommonStatus } from '../../models/common-state';
import axios from 'axios';
import { defaultError, defaultLoading } from '../../app/common-redux-use';
import { CurrentUserModel } from '../../models/CurrentUserModel';

enum AuthStatusEnum {
  unauthenticated = 'unauthenticated',
  authenticated = 'authenticated',
}

type AuthStatus = AuthStatusEnum | CommonStatus;
export const AuthStatus = { ...AuthStatusEnum, ...CommonStatus };

export interface AuthState extends CommonState {
  accessToken: string | null;
  refreshToken: string | null;
  exp: number | null;
  userEmail: string | null;
  currentUser: CurrentUserModel | null;
  errorMessage: string | null;
  status: AuthStatus[keyof AuthStatus];
  interceptorsIDs: { request: number | null; response: number | null };
  redirectToSplitRegistration: boolean;
}

const authURL = `${process.env.REACT_APP_API_URL!}auth`;

const initialState: AuthState = {
  accessToken: localStorage.getItem('accessToken') || null,
  refreshToken: localStorage.getItem('refreshToken') || null,
  currentUser: null,
  exp: +localStorage.getItem('exp')! || null,
  userEmail: localStorage.getItem('userEmail') || null,
  errorMessage: null,
  interceptorsIDs: { request: null, response: null },
  redirectToSplitRegistration: false,
  status:
    AuthStatus[
      !!localStorage.getItem('accessToken')
        ? 'authenticated'
        : 'unauthenticated'
    ],
};

export const authenticate = createAsyncThunk(
  'auth/signIn',
  async ({ username, password }: { username: string; password: string }) => {
    const authInfo = await Auth.signIn({ username, password });
    const currentUser = (
      await axios.get<CurrentUserModel>(`${authURL}/user`, {
        headers: {
          Authorization: `Bearer ${authInfo.signInUserSession.accessToken.jwtToken}`,
        },
      })
    ).data;
    return {
      isEmailVerified: authInfo.attributes?.email_verified,
      accessToken: authInfo.signInUserSession.accessToken.jwtToken,
      refreshToken: authInfo.signInUserSession.refreshToken.token,
      userEmail: authInfo.attributes.email,
      currentUser,
      exp: authInfo.signInUserSession.accessToken.payload.exp,
    };
  }
);

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async () => {
    return {
      currentUser: (await axios.get<CurrentUserModel>(`${authURL}/user`)).data,
    };
  }
);

export const sendEmailVerification = createAsyncThunk(
  'auth/sendEmailVerification',
  async () => {
    await Auth.verifyCurrentUserAttribute('email');
  }
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (code: string) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyUserAttributeSubmit(user, 'email', code);
    return {
      accessToken: user.signInUserSession.accessToken.jwtToken,
      refreshToken: user.signInUserSession.refreshToken.token,
      exp: user.signInUserSession.accessToken.payload.exp,
    };
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (userInfo: any, { rejectWithValue }: any) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL!}auth/register`,
        userInfo
      );
    } catch (e: any) {
      throw new Error(
        e.response.data.error || 'An error ocurred. Please contact our support.'
      );
    }
  }
);

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
  const session: any = await Auth.currentSession();
  return {
    accessToken: session.accessToken.jwtToken,
    refreshToken: session.refreshToken.token,
    //exp: session.accessToken.payload.exp,
  };
});

export const signOut = createAsyncThunk(
  'auth/signOut',
  async ({} = {}, _api) => {
    await Auth.signOut();
    return {
      status: AuthStatus.unauthenticated,
      interceptorsIDs: (_api.getState() as RootState).auth.interceptorsIDs,
    };
  }
);

export const generateCodeForgotPassword = createAsyncThunk(
  'auth/generateCodeForgotPassword',
  async (username: string) => {
    await Auth.forgotPassword(username);
  }
);

export const confirmForgotPassword = createAsyncThunk(
  'auth/confirmForgotPassword',
  async ({
    username,
    code,
    password,
  }: {
    username: string;
    code: string;
    password: string;
  }) => {
    await Auth.forgotPasswordSubmit(username, code, password);
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  }
);

export const createSplitRegistration = createAsyncThunk(
  'auth/createSplitRegistration',
  async ({ code }: { code: string }) => {
    try {
      await axios.post(`${authURL}/linkcode`, { code });
    } catch (e: any) {
      throw new Error(
        e.response.data.error || 'An error ocurred. Please contact our support.'
      );
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    cancelForgotPasswordProccess: (state) => {
      state.status = AuthStatus.unauthenticated;
    },
    setRequestInterceptorId: (state, action) => {
      state.interceptorsIDs.request = action.payload.id;
    },
    setResponseInterceptorId: (state, action) => {
      state.interceptorsIDs.response = action.payload.id;
    },
    setIsRedirectToSplitRegistration: (state, action) => {
      state.redirectToSplitRegistration = action.payload.isRedirect;
    },
    setStatusToAuthenticated: (state) => {
      state.status = AuthStatus.authenticated;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, defaultLoading)
      .addCase(authenticate.rejected, defaultError)
      .addCase(authenticate.fulfilled, (state, action) => {
        const {
          accessToken,
          refreshToken,
          exp,
          isEmailVerified,
          userEmail,
          currentUser,
        } = action.payload;

        if (!isEmailVerified) {
          return { ...state, status: AuthStatus.unauthenticated };
        }

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('exp', exp);
        localStorage.setItem('userEmail', userEmail);

        return {
          ...state,
          status: AuthStatus.authenticated,
          userEmail,
          accessToken,
          refreshToken,
          exp,
          errorMessage: null,
          currentUser,
        };
      })
      .addCase(getCurrentUser.pending, defaultLoading)
      .addCase(getCurrentUser.rejected, defaultError)
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        return { ...state, currentUser: action.payload.currentUser };
      })
      .addCase(sendEmailVerification.pending, defaultLoading)
      .addCase(sendEmailVerification.rejected, defaultError)
      .addCase(sendEmailVerification.fulfilled, (state) => {
        return { ...state, status: AuthStatus.unauthenticated };
      })
      .addCase(verifyEmail.pending, defaultLoading)
      .addCase(verifyEmail.rejected, defaultError)
      .addCase(verifyEmail.fulfilled, (state, action) => {
        const { accessToken, refreshToken, exp } = action.payload;

        return {
          ...state,
          status: AuthStatusEnum.authenticated,
          accessToken,
          refreshToken,
          exp,
          errorMessage: null,
        };
      })
      .addCase(signUp.pending, defaultLoading)
      .addCase(signUp.rejected, (state, action) => {
        if (
          action.error.message?.includes(
            'This email is already registered with a different code'
          )
        ) {
          return { ...state, redirectToSplitRegistration: true };
        }
        return {
          ...state,
          status: CommonStatus.failed,
          errorMessage: (action as any)?.error.message,
          redirectToSplitRegistration: true,
        };
      })
      .addCase(signUp.fulfilled, (state) => {
        return { ...state, status: AuthStatus.unauthenticated };
      })
      .addCase(signOut.pending, defaultLoading)
      .addCase(signOut.rejected, defaultError)
      .addCase(signOut.fulfilled, (state, action) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('exp');
        localStorage.removeItem('userEmail');

        if (action.payload.interceptorsIDs.request !== null)
          axios.interceptors.request.eject(
            action.payload.interceptorsIDs.request!
          );
        if (action.payload.interceptorsIDs.response !== null)
          axios.interceptors.response.eject(
            action.payload.interceptorsIDs.response!
          );

        return {
          ...state,
          status: AuthStatus.unauthenticated,
          accessToken: null,
          refreshToken: null,
          exp: null,
          errorMessage: null,
        };
      })
      .addCase(generateCodeForgotPassword.rejected, defaultError)
      .addCase(generateCodeForgotPassword.pending, defaultLoading)
      .addCase(generateCodeForgotPassword.fulfilled, (state) => {
        return { ...state, status: AuthStatus.unauthenticated };
      })
      .addCase(confirmForgotPassword.rejected, defaultError)
      .addCase(confirmForgotPassword.pending, defaultLoading)
      .addCase(confirmForgotPassword.fulfilled, (state) => {
        return { ...state, status: AuthStatus.unauthenticated };
      })
      .addCase(refreshToken.rejected, defaultError)
      .addCase(refreshToken.fulfilled, (state, action) => {
        const { accessToken, refreshToken } = action.payload;
        state.status = AuthStatus.authenticated;
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        return {
          ...state,
          status: AuthStatus.authenticated,
          accessToken,
          refreshToken,
        };
        //state.exp = exp;
      })
      .addCase(changePassword.pending, defaultLoading)
      .addCase(changePassword.rejected, defaultError)
      .addCase(changePassword.fulfilled, (state) => {
        return { ...state, status: AuthStatus.authenticated };
      })
      .addCase(createSplitRegistration.pending, defaultLoading)
      .addCase(createSplitRegistration.rejected, defaultError)
      .addCase(createSplitRegistration.fulfilled, (state) => {
        return { ...state, status: AuthStatus.authenticated };
      });
  },
});

export const {
  cancelForgotPasswordProccess,
  setRequestInterceptorId,
  setResponseInterceptorId,
  setIsRedirectToSplitRegistration,
  setStatusToAuthenticated,
} = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.status !== AuthStatus.unauthenticated;
export const selectExp = (state: RootState) => state.auth.exp;
export const selectIsLoading = (state: RootState) =>
  state.auth.status === AuthStatus.loading;
export const selectEmail = (state: RootState) => state.auth.userEmail;
export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectInterceptorIDs = (state: RootState) =>
  state.auth.interceptorsIDs;
export const selectRedirectToSplitRegistration = (state: RootState) =>
  state.auth.redirectToSplitRegistration;

export default authSlice.reducer;
