import { Button, Divider, useMediaQuery, useTheme } from '@mui/material';
import styled from 'styled-components';
import IgbBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import { setIsCollapsed } from '../../../../features/sideMenu/sideMenuSlice';

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary['500']};
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default function PageHeader({ title }: { title: string }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();
  const openMenu = () => {
    dispatch(setIsCollapsed({ isCollapsed: false }));
  };

  return (
    <div>
      <TitleContainer>
        <Title>
          {title} <IgbBreadcrumbs />
        </Title>
        {isMobile && (
          <Button
            sx={{ paddingRight: 0, minWidth: 'fit-content' }}
            onClick={openMenu}
            type="button"
          >
            <MenuIcon />
          </Button>
        )}
      </TitleContainer>
      <Divider />
    </div>
  );
}
