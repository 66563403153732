import { useEffect, useState } from 'react';
import './App.css';
import AuthContainer from './views/Auth/AuthContainer';
import { Route, Routes, useNavigate } from 'react-router-dom';
import RequireAuth from './features/auth/RequireAuth';
import MainContainer from './views/Main/Main';
import Login from './views/Auth/Login/Login';
import PasswordRecovery from './views/Auth/PasswordRecovery/PasswordRecovery';
import Statement from './views/Main/Statement/Statement';
import ComingSoon from './views/Main/ComingSoon/ComingSoon';
import ManageFiles from './views/Main/Admin/ManageFiles/ManageFiles';
import Admin from './views/Main/Admin/Admin';
import ChangePassword from './views/Main/Admin/ChangePassword/ChangePassword';
import { Amplify } from 'aws-amplify';
import { showErrorSnackbardOnFailedStatus } from './helpers/showErrorSnackbardOnFailedStatus';
import { useSnackbar } from 'notistack';
import EmailVerification from './views/Auth/EmailVerification/EmailVerification';
import SignUp from './views/Auth/SignUp/SignUp';
import PersistAuth from './features/auth/PersistAuth';
import Home from './views/Main/Home/Home';
import SplitRegistration from './views/Main/Admin/SplitRegistration/SplitRegistration';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION!,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID!,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_COGNITO_USERPOOL_WEBCLIENT_ID!,
  },
});

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const unsubscribeSnackbarListener =
    showErrorSnackbardOnFailedStatus(enqueueSnackbar);

  useEffect(() => {
    return () => {
      unsubscribeSnackbarListener();
    };
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PersistAuth>
              <RequireAuth>
                <MainContainer />
              </RequireAuth>
            </PersistAuth>
          }
        >
          <Route path="" element={<Home title="Home" />}></Route>
          <Route path="home" element={<Home title="Home" />}></Route>
          <Route path="statement" element={<Statement />}></Route>
          <Route
            path="decimal"
            element={<ComingSoon title="Decimal" />}
          ></Route>
          <Route path="admin" element={<Admin />}>
            <Route path="manage-files" element={<ManageFiles />}></Route>
            <Route
              path="split-registration"
              element={<SplitRegistration />}
            ></Route>
            <Route path="change-password" element={<ChangePassword />}></Route>
          </Route>
        </Route>
        <Route path="/auth" element={<AuthContainer />}>
          <Route path="login" element={<Login />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="password-recovery" element={<PasswordRecovery />} />
          <Route
            path="email-verification"
            element={<EmailVerification />}
          ></Route>
        </Route>
      </Routes>
    </>
  );
}
