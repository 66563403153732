import {
  SideMenuContainer,
  Img,
  ImgContainer,
  SideMenuItem,
  EmptyForHover,
  SideMenuSubItem,
  LogoutContainer,
} from './styles';
import logo from '../../../../logo.svg';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import SettingsIcon from '@mui/icons-material/Settings';
import AddLinkIcon from '@mui/icons-material/AddLink';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SideMenuExpandItem from './components/SideMenuExpandItem';
import PasswordIcon from '@mui/icons-material/Password';
import {
  Avatar,
  Backdrop,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import {
  selectCurrentUser,
  signOut,
} from '../../../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import Theme from '../../../../Theme';
import {
  selectIsCollapsed,
  setIsCollapsed,
} from '../../../../features/sideMenu/sideMenuSlice';
import { setShowStatements } from '../../../../features/statements/statementSlice';

interface MenuItem {
  route: string;
  title: string;
  icon: ReactElement<any, any>;
  childrenRoutes?: MenuItem[];
}

export default function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const collapsed = useSelector(selectIsCollapsed);
  const setCollapsed = (isCollapsed: boolean) => {
    dispatch(setIsCollapsed({ isCollapsed }));
  };

  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    setCollapsed(isMobile);
  }, []);

  const menuItems: MenuItem[] = [
    { route: '/home', title: 'Home', icon: <HomeIcon /> },
    { route: '/statement', title: 'Statement', icon: <ReceiptIcon /> },
    { route: '/decimal', title: 'Decimal', icon: <LooksOneIcon /> },
    {
      route: '/admin',
      title: 'Admin',
      icon: <AdminPanelSettingsIcon />,
      childrenRoutes: [
        {
          route: '/admin/manage-files',
          title: 'Manage files',
          icon: <SettingsIcon fontSize="small" />,
        },
        {
          route: '/admin/split-registration',
          title: 'Link registration',
          icon: <AddLinkIcon fontSize="small" />,
        },
        {
          route: '/admin/change-password',
          title: 'Change password',
          icon: <PasswordIcon fontSize="small" />,
        },
      ],
    },
  ];
  const [itemActive, setItemActive] = useState<number>(
    menuItems.indexOf(
      menuItems.find(
        (item) =>
          (location.pathname === '/' && item.route === '/home') ||
          location.pathname.includes(item.route)
      )!
    )
  );
  const [subItemActive, setSubItemActive] = useState<number>(-1);

  useEffect(() => {
    setSubItemActive(
      menuItems[itemActive].childrenRoutes
        ? menuItems[itemActive].childrenRoutes!.indexOf(
            menuItems[itemActive]!.childrenRoutes?.find((subItem) =>
              location.pathname.includes(subItem.route)
            )!
          )
        : -1
    );
  }, []);

  const navigateTo = (route: string) => {
    dispatch(setShowStatements({ show: false }));
    navigate(route);
  };

  const logout = async () => {
    const signOutInfo = await dispatch(signOut());
    if (signOutInfo.meta.requestStatus === 'fulfilled') navigate('/auth/login');
  };

  const getItemWithSubmenu = (
    { route, title, icon, childrenRoutes }: MenuItem,
    index: number
  ) => {
    return (
      <SideMenuExpandItem
        key={route + title}
        onClick={() => {
          setItemActive(index);
        }}
        icon={icon}
        title={title}
        isActive={index === itemActive}
        isExpanded={index === itemActive}
      >
        <div>
          {childrenRoutes!.map((childItem: MenuItem, childItemIndex) => {
            return (
              <SideMenuSubItem
                key={title + childItem.title}
                onClick={() => {
                  setSubItemActive(childItemIndex);
                  navigateTo(childItem.route);
                }}
                active={
                  itemActive === index && subItemActive === childItemIndex
                }
              >
                {childItem.icon}
                <span>{childItem.title}</span>
              </SideMenuSubItem>
            );
          })}
        </div>
      </SideMenuExpandItem>
    );
  };

  const getItem = ({ route, title, icon }: MenuItem, index: number) => {
    return (
      <SideMenuItem
        key={route + title}
        onClick={() => {
          setItemActive(index);
          setSubItemActive(-1);
          navigateTo(route);
        }}
        active={index === itemActive}
      >
        {icon}
        <span>{title}</span>
      </SideMenuItem>
    );
  };

  const getMenu = () => {
    return (
      <SideMenuContainer collapsed={collapsed} breakpoints={theme.breakpoints}>
        <ImgContainer onClick={() => setCollapsed(!collapsed)}>
          <Img src={logo} />
        </ImgContainer>
        {menuItems.map(({ route, title, icon, childrenRoutes }, index) => {
          return !childrenRoutes
            ? getItem({ route, title, icon }, index)
            : getItemWithSubmenu({ route, title, icon, childrenRoutes }, index);
        })}
        <EmptyForHover onClick={() => setCollapsed(!collapsed)}>
          <div>
            <Avatar
              sx={{
                bgcolor: Theme.colors.primaryContrast[500],
                color: Theme.colors.primary[500],
              }}
            >
              {currentUser?.firstname ? currentUser.firstname.slice(0, 1) : ''}
            </Avatar>
            {!collapsed && (
              <Typography>Hello, {currentUser?.firstname}!</Typography>
            )}
          </div>
        </EmptyForHover>
        <LogoutContainer>
          {collapsed ? (
            <Button onClick={logout} size="small">
              <LogoutIcon />
            </Button>
          ) : (
            <Button
              onClick={() => {
                logout();
                setCollapsed(true);
              }}
              fullWidth
              startIcon={<LogoutIcon />}
            >
              Sign out
            </Button>
          )}
        </LogoutContainer>
      </SideMenuContainer>
    );
  };

  return (
    <div>
      {isMobile ? (
        <>
          {getMenu()}
          <Backdrop
            sx={{ zIndex: 999 }}
            open={isMobile && !collapsed}
            transitionDuration={200}
            onClick={() => {
              setCollapsed(true);
            }}
          ></Backdrop>
        </>
      ) : (
        getMenu()
      )}
    </div>
  );
}
