import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommonState, CommonStatus } from '../../models/common-state';
import { defaultError } from '../../app/common-redux-use';
import axios from 'axios';
import { StatementModel } from '../../models/StatementModel';
import { RootState } from '../../app/store';
import { format, subMonths } from 'date-fns';
import { PERMISSIONS } from '../auth/permissions';
import Statement from '../../views/Main/Statement/Statement';

enum StatementStatusEnum {
  empty = 'empty',
  downloading = 'downloading',
}

type StatementStatus = StatementStatusEnum | CommonStatus;
const StatementStatus = { ...StatementStatusEnum, ...CommonStatus };

export interface StatementState extends CommonState {
  statements: StatementModel[];
  page: number | null;
  count: number;
  errorMessage: string | null;
  status: StatementStatus[keyof StatementStatus];
  showStatements: boolean;
}

const initialState: StatementState = {
  statements: [],
  page: null,
  count: 0,
  errorMessage: null,
  status: StatementStatus.empty,
  showStatements: false,
};

const statementURL = `${process.env.REACT_APP_API_URL!}statement`;

export const downloadFile = (file: any, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([file.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

let currRequestSource: any;

export const getStatements = createAsyncThunk(
  'statements/getStatements',
  async ({ params, page }: { params: any; page: number }, { getState }) => {
    if (currRequestSource) {
      currRequestSource.cancel();
    }
    currRequestSource = axios.CancelToken.source();

    const configEndpoint = {
      cancelToken: currRequestSource.token,
      params: {
        ...params,
        limit: 25,
        offset: 25 * page,
      },
    };

    let count, statements;

    const detail = (
      await axios.get<{
        count: number;
        results: StatementModel[];
      }>(`${statementURL}/detail`, configEndpoint)
    ).data;
    count = detail.count;
    statements = detail.results.map((item: StatementModel) => ({
      ...item,
      statement_id: item.statement.id,
    }));

    return {
      statements,
      count,
      page,
    };
  }
);

export const getStatementFile = createAsyncThunk(
  'statements/getStatementFile',
  async ({ statement }: { statement: StatementModel }) => {
    console.log(statement);
    const file = await axios.get(`${statementURL}/pdf`, {
      params: { statement: statement.statement_id },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    downloadFile(
      file,
      `${statement.statement.sales_date}_${
        statement.property.property_name1
      }_${format(new Date(), 'yyyyMMdd')}.pdf`
    );
  }
);

export const getStatementBulkFiles = createAsyncThunk(
  'statements/getStatementBulkFiles',
  async ({ statements }: { statements: string }) => {
    const file = await axios.get(`${statementURL}/pdfbulk`, {
      params: { statements },
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/zip',
      },
    });

    downloadFile(file, `statements.zip`);
  }
);

export const getAllStatementsCSV = createAsyncThunk(
  'statements/getAllStatementsCSV',
  async ({ mode, filters }: { mode: string; filters: any }): Promise<any> => {
    const params = {
      [mode]: 1,
      ...filters,
    };
    const file = await axios.get(`${statementURL}/statement_all`, {
      params,
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'text/csv',
      },
    });

    if (file.status === 200) downloadFile(file, 'statements.csv');
    return {
      status: file.status,
    };
  }
);

export const statementsSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setShowStatements: (state, action) => ({
      ...state,
      showStatements: !!action.payload.show,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatements.pending, (state) => {
        return { ...state, status: StatementStatus.loading, statements: [] };
      })
      .addCase(getStatements.rejected, (state: any, action: any) => {
        if (action.error.code === 'ERR_CANCELED') return { ...state };
        return {
          ...state,
          status: CommonStatus.failed,
          errorMessage: (action as any)?.error.message,
        };
      })
      .addCase(getStatements.fulfilled, (state, action) => {
        const { count, page, statements } = action.payload;

        return {
          ...state,
          count,
          statements,
          page,
          status: StatementStatus.success,
        };
      })
      .addCase(getStatementFile.pending, (state) => ({
        ...state,
        status: StatementStatus.downloading,
      }))
      .addCase(getStatementFile.rejected, defaultError)
      .addCase(getStatementFile.fulfilled, (state) => {
        return { ...state, status: StatementStatus.success };
      })
      .addCase(getStatementBulkFiles.pending, (state) => {
        return { ...state, status: StatementStatus.downloading };
      })
      .addCase(getStatementBulkFiles.rejected, defaultError)
      .addCase(getStatementBulkFiles.fulfilled, (state) => {
        return { ...state, status: StatementStatus.success };
      })
      .addCase(getAllStatementsCSV.pending, (state) => {
        return { ...state, status: StatementStatus.downloading };
      })
      .addCase(getAllStatementsCSV.rejected, defaultError)
      .addCase(getAllStatementsCSV.fulfilled, (state) => {
        return { ...state, status: StatementStatus.success };
      });
  },
});

export const selectStatements = (state: RootState) =>
  state.statement.statements;

export const selectIsLoading = (state: RootState) =>
  state.statement.status === StatementStatus.loading;

export const selectShowStatements = (state: RootState) =>
  state.statement.showStatements;

export const selectIsDownloading = (state: RootState) =>
  state.statement.status === StatementStatus.downloading;

export const selectCount = (state: RootState) => state.statement.count;

export const { setShowStatements } = statementsSlice.actions;

export default statementsSlice.reducer;
