import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import statementsReducer from '../features/statements/statementSlice';
import filesReducer from '../features/files/filesSlice';
import sideMenuReducer from '../features/sideMenu/sideMenuSlice';
import sendersReducer from '../features/senders/senderSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    statement: statementsReducer,
    files: filesReducer,
    sideMenu: sideMenuReducer,
    sender: sendersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
