import { Typography } from '@mui/material';
import styled from 'styled-components';

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return props.theme.colors.success;
  }
  if (props.isDragReject) {
    return props.theme.colors.error;
  }
  if (props.isFocused) {
    return props.theme.colors.primary['500'];
  }
  return props.theme.colors.primaryContrast['500'];
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default function IgbDragNDrop({ rootProps, inputProps }: any) {
  return (
    <Container {...rootProps}>
      <input {...inputProps} />
      <Typography>
        Drag 'n' drop some files here, or click to select files
      </Typography>
    </Container>
  );
}
