import { Chip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import InfoBubble from '../../../components/InfoBubble/InfoBubble';
import { FormattedNumber } from 'react-intl';

export const COLUMNS: GridColDef[] = [
  {
    field: 'sender__name',
    headerName: 'Operator',
    flex: 1,
    minWidth: 250,
    valueGetter: (params) => params.row.sender.name,
  },
  {
    field: 'property__header_id',
    headerName: 'Property No',
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => params.row.property.header_id,
  },
  {
    field: 'property__header_name',
    headerName: 'Property Name',
    flex: 1,
    minWidth: 250,
    valueGetter: (params) => params.row.property.header_name,
  },
  {
    field: 'statement__sales_date',
    headerName: 'Sales Date',
    minWidth: 120,
    valueGetter: (params) => params.row.statement.sales_date,
    valueFormatter: (date) =>
      format(new Date(date.value.slice(0, 19)), 'MM-yyyy'),
  },
  {
    field: 'statement__is_current',
    headerName: 'Revision Status',
    sortable: false,
    minWidth: 120,
    valueGetter: (params) => params.row.statement.is_current,
    renderCell: (params: GridRenderCellParams<any>) => {
      const isCurrent = params.value;
      return (
        <Chip
          label={isCurrent ? 'Current' : 'Revised'}
          color={isCurrent ? 'success' : 'info'}
          sx={
            !isCurrent ? { backgroundColor: '#fccad3', color: '#4f4e4e' } : null
          }
        />
      );
    },
  },
  {
    field: 'statement__statement_date',
    headerName: 'Processed Date',
    minWidth: 180,
    valueGetter: (params) => params.row.statement.statement_date,
    valueFormatter: (date) =>
      date.value
        ? format(new Date(date.value.slice(0, 19)), 'MM-dd-yyyy')
        : '--',
  },
];

export const PREMIUM_COLUMNS: GridColDef[] = [
  {
    field: 'entitled_interest',
    headerName: 'Entitled Interest',
    minWidth: 150,
    valueGetter: (params) => params.row.entitled_interest,
    valueFormatter: ({ value }) => Number(value).toFixed(8),
  },
  {
    field: 'entitled_volume',
    headerName: 'Entitled Volume',
    minWidth: 150,
    valueGetter: (params) => params.row.entitled_volume,
    renderCell: ({ value }) => (
      <FormattedNumber value={value.toFixed(0)} numberingSystem="traditio" />
    ),
  },
  {
    field: 'calc_total_sales_volume',
    headerName: 'Total Sales Volume',
    minWidth: 191,
    valueGetter: (params) => params.row.calc_total_sales_volume,
    renderCell: ({ value }) => (
      <FormattedNumber value={value.toFixed(0)} numberingSystem="traditio" />
    ),
    renderHeader: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <span>{params.colDef.headerName}</span>
          <InfoBubble>
            <Typography>
              The volume displayed is the sum of Loss Use, if applicable, and
              Actual Volume.
            </Typography>
          </InfoBubble>
        </div>
      );
    },
  },
  {
    field: 'calc_current_month_balance',
    headerName: 'Calculated Current Month',
    minWidth: 235,
    valueGetter: (params) => params.row.calc_current_month_balance,
    renderCell: ({ value }) => (
      <FormattedNumber value={value} numberingSystem="traditio" />
    ),
    renderHeader: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <span>{params.colDef.headerName}</span>
          <InfoBubble>
            <Typography>
              The volume displayed is the calculated difference of Total Actual
              Volume and Entitled Volume. This is calculated to depict a true
              Current Month activity excluding any Prior Period Adjustments.
            </Typography>
          </InfoBubble>
        </div>
      );
    },
  },
  {
    field: 'adjustments',
    headerName: 'Adjustments',
    minWidth: 150,
    valueGetter: (params) => params.row.adjustments,
    renderCell: ({ value }) => (
      <FormattedNumber value={value} numberingSystem="traditio" />
    ),
    renderHeader: (params) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
          <span>{params.colDef.headerName}</span>
          <InfoBubble>
            <Typography>
              The Adjustment Volume displayed is a summation of any Adjustment
              and/or Transfer Volume.
            </Typography>
          </InfoBubble>
        </div>
      );
    },
  },
  {
    field: 'cumulative_balance',
    headerName: 'Cumulative Balance',
    minWidth: 150,
    renderCell: ({ value }) => (
      <FormattedNumber value={value} numberingSystem="traditio" />
    ),
    valueGetter: (params) => params.row.cumulative_balance,
  },
];
