import { Form, Formik } from 'formik';
import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import { Field } from 'formik';
import IgbTextField from '../../../../components/CustomTextField/TextField';
import * as Yup from 'yup';
import styled from 'styled-components';
import IgbDatePicker from '../../../../components/CustomDatePicker/DatePicker';
import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import {
  selectIsDownloading,
  selectIsLoading,
  selectShowStatements,
  setShowStatements,
} from '../../../../features/statements/statementSlice';
import { thresholdDates } from '../../../../helpers/thresholdDates';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../../../helpers/getDayOfMonth';
import { SenderModel } from '../../../../models/SendersModel';
import CustomSelect from '../../../../components/CustomSelect/Select';
import CustomIndeterminateSwitch from '../../../../components/CustomIndeterminateSwitch/CustomIndeterminateSwitch';
import React from 'react';
import FalseSwitch from '../../../../components/CustomIndeterminateSwitch/false-switch.png';
import TrueSwitch from '../../../../components/CustomIndeterminateSwitch/true-switch.png';
import IndeterminateSwitch from '../../../../components/CustomIndeterminateSwitch/neutral-switch.png';
import IgbTextFieldWithSubSelecton from '../../../../components/CustomTextFieldWithSubSelecton/TextFieldWithSubSelecton';
import IgbRangeDatePicker from '../../../../components/CustomRangeDatePicker/IgbRangeDatePicker';
import InfoBubble from '../../../../components/InfoBubble/InfoBubble';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';

const StyledButton = styled(LoadingButton)`
  height: 100%;
`;

const FilterFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const FilterFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const FilterContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default function StatementFilters({
  idsSelected,
  senders,
  handleOnSubmit,
  handleOnDownload,
  handleResetSorting,
}: {
  idsSelected: string[];
  senders: SenderModel[];
  handleOnSubmit: (values: any) => any;
  handleOnDownload: () => any;
  handleResetSorting: () => void;
}) {
  const isLoading = useSelector(selectIsLoading);
  const isDownloading = useSelector(selectIsDownloading);
  const showStatements = useSelector(selectShowStatements);
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = Yup.object().shape({
    sender_name: Yup.string(),
    sales_date__gte: Yup.date(),
    sales_date__lte: Yup.date(),
    statement_date__gte: Yup.date(),
    statement_date__lte: Yup.date(),
    property_name: Yup.string(),
    propertyNameMode: Yup.string(),
    property_no: Yup.string(),
    is_current: Yup.mixed<boolean | 'indeterminate'>(),
  });

  const propertyNameModeOptions = [
    { label: 'Contains', value: '__icontains' },
    { label: 'Exact', value: '__iexact' },
    { label: 'Starts with', value: '__startswith' },
  ];

  const initialValues = {
    sender_name: '',
    sales_date__gte: '',
    sales_date__lte: '',
    statement_date__gte: '',
    statement_date__lte: '',
    property_name: '',
    propertyNameMode: propertyNameModeOptions[0].value,
    property_no: '',
    is_current: 'indeterminate',
  };

  const [minDate, setMinDate] = useState<Date | string>(
    initialValues.sales_date__gte
  );
  const [maxDate, setMaxDate] = useState<Date | string>(
    initialValues.sales_date__lte
  );

  const [formTouched, setFormTouched] = useState<boolean>(false);

  const customHandleOnSubmit = (values: any) => {
    dispatch(setShowStatements({ show: true }));
    handleOnSubmit(values);
    setFormTouched(false);
  };

  // Clear button Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const statusModes = [
    { label: 'Current version', value: true, img: TrueSwitch },
    { label: 'Revised', value: false, img: FalseSwitch },
    { label: 'All versions', value: 'indeterminate', img: IndeterminateSwitch },
  ];

  const [downloadAllMode, setDownloadAllMode] = useState<
    'all_details' | 'my_details' | 'summarize'
  >('all_details');

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={customHandleOnSubmit}
    >
      {(formik) => {
        const { values, handleChange, handleBlur, resetForm, setFieldValue } =
          formik;

        const customHandleChange = (e: React.ChangeEvent<any>) => {
          handleChange(e);
          setFormTouched(true);
        };
        return (
          <FilterContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={12}>
                <CustomSelect
                  label="Operator"
                  name="sender_name"
                  value={values.sender_name}
                  onChange={customHandleChange}
                  onBlur={handleBlur}
                >
                  {senders.map((sender, index) => (
                    <MenuItem key={sender.name + index} value={sender.name}>
                      {sender.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  component={IgbTextFieldWithSubSelecton}
                  onChange={customHandleChange}
                  name="property_name"
                  type="text"
                  label="Property Name"
                  select={{
                    name: 'propertyNameMode',
                    value: values.propertyNameMode,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    options: propertyNameModeOptions.map((opt, i) => (
                      <MenuItem key={opt.value + i} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    )),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Field
                  component={IgbDatePicker}
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  name="sales_date__gte"
                  minDate={thresholdDates.min}
                  maxDate={maxDate || thresholdDates.max}
                  onCustomClose={(prevValue: string | Date) => {
                    if (
                      values.sales_date__gte === '' &&
                      prevValue !== values.sales_date__gte
                    )
                      setFormTouched(true);
                  }}
                  onMonthChange={(_fromDate: Date) => {
                    console.log(_fromDate);
                    setFormTouched(true);
                    if (!_fromDate) return;
                    const fromDate = getFirstDayOfMonth(_fromDate);
                    const toDate = getLastDayOfMonth(_fromDate);
                    setFieldValue('sales_date__lte', toDate);
                    setMinDate(fromDate);
                  }}
                  label="From sales date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Field
                  component={IgbDatePicker}
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  name="sales_date__lte"
                  minDate={minDate || thresholdDates.min}
                  maxDate={thresholdDates.max}
                  onMonthChange={(_toDate: Date) => {
                    setFormTouched(true);
                    if (!_toDate) return;
                    const toDate = getLastDayOfMonth(_toDate);
                    setMaxDate(toDate);
                  }}
                  label="To sales date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  component={IgbTextField}
                  name="property_no"
                  type="text"
                  label="Property No"
                  onChange={customHandleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  component={IgbRangeDatePicker}
                  startLabel="From processed date"
                  endLabel="To processed date"
                  onChange={() => setFormTouched(true)}
                  startName="statement_date__gte"
                  endName="statement_date__lte"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <Field
                  style={{ marginRight: '5px' }}
                  component={CustomIndeterminateSwitch}
                  onChange={(ev: React.ChangeEvent<any>) => {
                    setFormTouched(true);
                  }}
                  name="is_current"
                  label={
                    statusModes.find((mode) => mode.value === values.is_current)
                      ?.label || ''
                  }
                />
                <InfoBubble>
                  {statusModes.map((mode) => {
                    return (
                      <div key={mode.label.replace(' ', '')}>
                        <img src={mode.img} />
                        <Typography component="h6">{mode.label}</Typography>
                      </div>
                    );
                  })}
                </InfoBubble>
              </Grid>
            </Grid>
            <FilterFooterContainer>
              <FilterFooter>
                <StyledButton
                  loading={(isLoading || isDownloading) && showStatements}
                  disabled={idsSelected.length === 0}
                  variant="outlined"
                  type="button"
                  onClick={handleOnDownload}
                  disableElevation
                >
                  Download
                </StyledButton>
                <StyledButton
                  loading={(isLoading || isDownloading) && showStatements}
                  disableElevation
                  variant="contained"
                  type="submit"
                >
                  Search
                </StyledButton>
                <StyledButton
                  loading={(isLoading || isDownloading) && showStatements}
                  disableElevation
                  onClick={handleMenuClick}
                  type="button"
                >
                  Clear
                </StyledButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      resetForm();
                      dispatch(setShowStatements({ show: false }));
                      setFieldValue('is_current', 'indeterminate');
                      handleMenuClose();
                    }}
                  >
                    Clear filters
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleResetSorting();
                      handleMenuClose();
                    }}
                  >
                    Reset sort
                  </MenuItem>
                </Menu>
              </FilterFooter>
            </FilterFooterContainer>
          </FilterContainer>
        );
      }}
    </Formik>
  );
}
