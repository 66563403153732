import { Button, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch } from '../../../app/store';
import IgbTextField from '../../../components/CustomTextField/TextField';
import {
  selectIsLoading,
  sendEmailVerification,
  verifyEmail,
} from '../../../features/auth/authSlice';
import { Form } from '../styles';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect } from 'react';

export default function EmailVerification() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(sendEmailVerification());
  }, []);

  const handleOnSubmit = async ({ code }: any) => {
    const verifyEmailInfo = await dispatch(verifyEmail(code));
    if (verifyEmailInfo.meta.requestStatus === 'fulfilled') navigate('/home');
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Verification code is a required field'),
  });

  return (
    <Formik
      initialValues={{ code: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty } = formik;
        return (
          <Form>
            <Typography component="h1">Verify your e-mail</Typography>
            <Typography component="p">
              You'll receive an confirmation code on your e-mail.
            </Typography>
            <Field
              component={IgbTextField}
              name="code"
              label="Verification code"
            />

            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!dirty || !isValid}
              variant="contained"
            >
              Verify my e-mail!
            </LoadingButton>
            <Typography component="p">
              Did not receive the code yet?{' '}
              <Button onClick={() => dispatch(sendEmailVerification())}>
                Send again!
              </Button>
            </Typography>
          </Form>
        );
      }}
    </Formik>
  );
}
