import { Outlet } from 'react-router-dom';
import { Container, Img } from './styles';
import logo from '../../logo.svg';
import { Card, CardContent } from '@mui/material';
import Footer from '../../components/Footer/Footer';

export default function AuthContainer() {
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Container>
        <Img src={logo} />
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Outlet />
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </div>
  );
}
