import { Field, Formik, Form as FormikForm } from 'formik';
import styled from 'styled-components';
import IgbTextField from '../../../../components/CustomTextField/TextField';
import * as Yup from 'yup';
import { Alert, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import {
  createSplitRegistration,
  selectIsLoading,
  setIsRedirectToSplitRegistration,
  setStatusToAuthenticated,
} from '../../../../features/auth/authSlice';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export default function SplitRegistration() {
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector(selectIsLoading);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(setIsRedirectToSplitRegistration({ isRedirect: false }));

    return () => {
      dispatch(setStatusToAuthenticated());
    };
  }, []);

  const handleOnSubmit = async (formValues: { code: string }) => {
    const splitRegInfo = await dispatch(createSplitRegistration(formValues));
    if (splitRegInfo.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar('Split registration successfully!', {
        variant: 'success',
        autoHideDuration: 1500,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string().required('Code is a required field.'),
  });

  return (
    <>
      <Alert severity="info">
        Enter Registration Code received in order to link new Statements to your
        existing Account. If you have multiple codes, Link each one separately.
      </Alert>
      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {(formik) => {
          const { isValid, dirty } = formik;
          return (
            <Form>
              <Field
                component={IgbTextField}
                name="code"
                type="text"
                label="Code"
              />
              <ActionsContainer>
                <LoadingButton
                  type="submit"
                  sx={{ width: 'auto', marginLeft: 'auto' }}
                  fullWidth
                  loading={isLoading}
                  disabled={!dirty || !isValid}
                  variant="contained"
                >
                  Link
                </LoadingButton>
              </ActionsContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
