import { Card, CardActions, ListItem } from '@mui/material';
import styled from 'styled-components';

export const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const UploadModalCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 50vw;
  max-height: 70vh;
`;

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const CardActionsContainer = styled(CardActions)`
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
`;

export const CustomListItem = styled(ListItem)``;
