import { CircularProgress, Stack } from '@mui/material';
import axios from 'axios';
import { differenceInMilliseconds } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from '../../app/store';
import {
  selectExp,
  selectAccessToken,
  signOut,
  refreshToken,
  selectInterceptorIDs,
  setRequestInterceptorId,
  setResponseInterceptorId,
  selectCurrentUser,
  getCurrentUser,
} from './authSlice';

let intervalRef: NodeJS.Timer;

export default function PersistAuth({ children }: any) {
  const exp = useSelector(selectExp);
  const accessToken = useSelector(selectAccessToken);
  const interceptorIDs = useSelector(selectInterceptorIDs);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const currUser = useSelector(selectCurrentUser);

  const [isLoading, setIsLoading] = useState<boolean>(
    interceptorIDs.request === null ||
      interceptorIDs.response === null ||
      currUser === null
  );

  const didMount = useRef(false);

  // Track logout due to token expiration
  useEffect(() => {
    intervalRef = setInterval(() => {
      const expDiff = exp! * 1000 - +new Date();
      if (exp && expDiff <= 0) {
        dispatch(signOut());
        navigate('/auth/login');
      }
    }, 1000);
  }, [exp]);
  useEffect(() => {
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  // Set request interceptors
  useEffect(() => {
    if (!exp || !accessToken) {
      setIsLoading(false);
      return;
    }

    if (interceptorIDs.request)
      axios.interceptors.request.eject(interceptorIDs.request!);
    if (interceptorIDs.response)
      axios.interceptors.response.eject(interceptorIDs.response!);

    const expDiff = differenceInMilliseconds(new Date(exp! * 1000), new Date());

    dispatch(
      setRequestInterceptorId({
        id: axios.interceptors.request.use(async (request) => {
          const baseUrl = process.env.REACT_APP_API_URL!;

          if (!request.url?.includes(baseUrl)) {
            return request;
          }

          if (!request.url?.includes('/auth')) {
            request.url = request.url + '/';
          }

          if (accessToken) {
            // @ts-ignore
            request.headers.common.Authorization = `Bearer ${accessToken}`;
          }

          return request;
        }),
      })
    );

    // Set response interceptors
    dispatch(
      setResponseInterceptorId({
        id: axios.interceptors.response.use((response) => {
          if (expDiff > 0 && expDiff <= 5 * 60 * 1000) dispatch(refreshToken());
          return response;
        }),
      })
    );
  }, [exp, accessToken]);

  useEffect(() => {
    if (!currUser) dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    setIsLoading(
      interceptorIDs.request === null ||
        interceptorIDs.response === null ||
        currUser === null
    );
  }, [interceptorIDs, currUser]);

  return !isLoading ? (
    children
  ) : (
    <Stack alignItems="center" sx={{ height: '100vh', width: '100vw' }}>
      <CircularProgress sx={{ margin: 'auto 0' }} />
    </Stack>
  );
}
