import { Button, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { AppDispatch } from '../../../../app/store';
import IgbTextField from '../../../../components/CustomTextField/TextField';
import {
  cancelForgotPasswordProccess,
  confirmForgotPassword,
  selectIsLoading,
} from '../../../../features/auth/authSlice';
import { Form } from '../../styles';
import LoadingButton from '@mui/lab/LoadingButton';

export default function PasswordRecoveryConfirm({ email }: { email: string }) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  const handleOnSubmit = async (values: {
    username: string;
    code: string;
    password: string;
  }) => {
    const confirmInfo = await dispatch(confirmForgotPassword(values));
    if (confirmInfo.meta.requestStatus === 'fulfilled') navigate('/auth/login');
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string().email().required('Email is a required field.'),
    code: Yup.string().required('The confirmation code is a required field.'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
        'Your password needs to have at least 8 characters, a uppercase letter, a lowercase letter, a number and a special character'
      )
      .required('The new password is a required field.'),
  });

  return (
    <Formik
      initialValues={{ username: email, code: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      {(formik) => {
        const { isValid, dirty } = formik;
        return (
          <Form>
            <Typography component="h1">Recover your password</Typography>
            <Field
              component={IgbTextField}
              name="username"
              label="Your email"
            />
            <Field
              component={IgbTextField}
              name="code"
              label="Confirmation code"
            />
            <Field
              component={IgbTextField}
              name="password"
              label="New password"
              type="password"
            />
            <LoadingButton
              type="submit"
              fullWidth
              loading={isLoading}
              disabled={!dirty || !isValid}
              variant="contained"
            >
              Create new password
            </LoadingButton>
            <Button
              variant="text"
              onClick={() => dispatch(cancelForgotPasswordProccess)}
            >
              <Link to="/auth/login">Back to login</Link>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}
